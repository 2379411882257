import { Link } from "react-router-dom";

function App() {
  return (
    <div className="app">
      <section className="navbar">
        <h1 style={{ margin: 0 }}>Wox Blue</h1>
        <div>
          <Link to="apps/blood_care" className="details">Blood care</Link>
          <a href="comming_soon" className="details">Coming soon...</a>
        </div>
        <button>Contactanos</button>
      </section>
      <section className="portada">
        <img src={process.env.PUBLIC_URL + '/imgs/ai.jpg'} alt="not found" className="back-img" />
        <div className="content container">
          <div style={{ width: '100%', maxWidth: 700 }}>
            <i className="small" style={{ fontSize: '.85em', color: '#ddd' }}>Desde el año 2022</i>
            <h1 className="header1" style={{ margin: 0 }}>
              Sumérgete en el mundo digital: Donde la innovación es infinita.
            </h1>
            <p className="details" style={{ color: '#ddd' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>
      </section>
      <section className="container">
        <div style={{paddingTop: '15em', paddingBottom: '15em', textAlign: 'center'}}>
          Este sitio aún esta en desarrollo
        </div>
      </section>
      <section className="footer container">
        <div style={{ fontSize: '.85em', color: '#ddd', textAlign: 'center', margin: '1em' }}>
          Todos los derechos reservados <a href="woxblue.com" style={{ color: '#fff' }}>@woxblue.com</a> desde 2022.
        </div>
      </section>
    </div>
  );
}

export default App;
